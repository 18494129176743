<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="video-container">
          <video autoplay loop muted>
            <source src="./../assets/video.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="wrapper">
          <div class="title big">
            Revolutionizing Digital Commerce:
          </div>
          <div class="title medium">
            An in-depth look into our AI-centric marketplace
          </div>
          <div class="desc">
            Unlocking the Future of Content Creation and Monetization through Artificial Intelligence
          </div>
          <router-link class="button dark" to="/product-list">
            Explore Marketplace
          </router-link>
        </div>
      </div>

      <div class="section features-section" id="features">
        <img class="bg" src="./../assets/bg.png" />
        <div class="wrapper">
          <div class="title big">Our Feautures</div>
          <ul class="list">
            <li class="item item1">
              <img class="line" src="./../assets/features/line1.svg" />
              <div class="num"></div>
              <div class="title">Legalized Content</div>
            </li>
            <li class="item item2">
              <img class="line" src="./../assets/features/line2.svg" />
              <div class="num"></div>
              <div class="title">Wide Assortment</div>
            </li>
            <li class="item item3">
              <img class="line" src="./../assets/features/line3.svg" />
              <div class="num"></div>
              <div class="title">Quick Verification</div>
            </li>
            <li class="item item4">
              <img class="line" src="./../assets/features/line4.svg" />
              <div class="num"></div>
              <div class="title">Global Audience</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section section-categories" id="categories">
        <div class="wrapper" v-if="categories.length">
          <div class="title big">
            Explore Our AI Product Categories
          </div>
          <div class="list">
            <div class="item" v-for="(item, i) in categories.slice(0,5)" :key="i">
              <img :src="imgDomain + item.image_url" class="img" @click="chooseCategory(item)"/>
              <div class="title">{{item.title}}</div>
            </div>
            <router-link class="item title link" to="/product-list">Marketplace</router-link>
          </div> 
        </div>
      </div>

      <div class="section section-how-it-works" id="how-it-works">
        <div class="wrapper">
          <div class="title big">How It Works</div>
          <div class="list">
            <img class="line" src="./../assets/howItWorks/line.svg" />
            <div class="item">
              <div class="title big">
                <img class="img" src="./../assets/howItWorks/1.svg" />
              </div>
              <div class="title small">Sign Up</div>
            </div>
            <div class="item">
              <div class="title big">
                <img class="img" src="./../assets/howItWorks/2.svg" />
              </div>
              <div class="title small">Browse Categories</div>
            </div>
            <div class="item">
              <div class="title big">
                <img class="img" src="./../assets/howItWorks/3.svg" />
              </div>
              <div class="title small">Purchase Products</div>
            </div>
            <div class="item">
              <div class="title big">
                <img class="img" src="./../assets/howItWorks/4.svg" />
              </div>
              <div class="title small">Implement</div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-contact" id="contact">
        <div class="wrapper">
          <div class="title medium">Don`t be shy, email us!</div>
          <div class="title big">{{ $parent.settings.support_email }}</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Home',
  props: ['currency'],
  components: {
  },
  data: function() {
    return {
      imgDomain: '',
      categories: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload;
      })
      .catch(() => {
         
      })
  },
  methods: {
    chooseCategory(item) {
      this.$emit('chooseCategory', item)
    },
  }
}
</script>